<template>
  <div>
    <v-container class="custom-container">
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="greeting-card">
            <v-row class="ma-0 pa-0">
              <v-col cols="8" :style="{ height: '280px' }">
                <v-card-title class="text-no-wrap pt-1 ps-2"> Genel Toplam Varlığım </v-card-title>
                <v-card-subtitle class="text-no-wrap ps-2 responsive-text">
                  Genel toplam güncel kur üzerinden anlık hesaplanmıştır!
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text class="d-flex justify-center align-center flex-column mt-2 pb-2 ps-2" style="height: 50%">
                  <div>
                    <p class="text-xl font-weight-semibold primary--text mb-2 responsive-text">
                      {{ totalBalance | currency }}
                    </p>
                  </div>
                </v-card-text>
              </v-col>
              <v-col cols="4">
                <v-img
                  contain
                  height="230"
                  width="159"
                  :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
                  class="greeting-card-bg"
                ></v-img>
                <v-img
                  contain
                  height="230"
                  max-width="40"
                  class="greeting-card-trophy"
                  src="@/assets/images/misc/trophy.png"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="align-start">
              <span class="font-weight-semibold">Toplam Varlığım</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="data in this.userBalanceData"
                  :key="data.title"
                  cols="6"
                  md="5"
                  class="d-flex align-center"
                >
                  <v-avatar
                    size="44"
                    :color="resolveStatisticsIconVariation(data.title).color"
                    rounded
                    class="elevation-1"
                  >
                    <v-icon dark color="white" size="30">
                      {{ resolveStatisticsIconVariation(data.title).icon }}
                    </v-icon>
                  </v-avatar>
                  <div class="ms-3">
                    <p class="text-xs mb-0">
                      {{ data.title }}
                    </p>
                    <h3 class="text-ml font-weight-semibold responsive-text">
                      {{ data.bakiye | currency({ symbol: data.title }) }}
                    </h3>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import { mdiAccountOutline, mdiCash, mdiCurrencyEur, mdiCurrencyTry, mdiCurrencyUsd, mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
export default {
  data() {
    return {
      userBalanceData: [],
      CurrenciesData: [],
      exchangeRates: {},
      convertedBalanceData: [],
      totalBalance: 0,

      // icons
      icons: {
        mdiAccountOutline,
        mdiCurrencyTry,
      },
    }
  },
  setup() {
    /*  const userBalanceTable = ref([])
    const fetchList = async () => {
      const loadingSelectStatus = ref(null)
      const loadingSelectText = ref('Lütfen Bekleyin Yükleniyor')
      const params = new URLSearchParams()
      params.append('method', 'getUserBalances')
      params.append('web_api', 1)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }
          this.userBalanceTable = response.data.response
          console.log('data', this.userBalanceTable)
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    } */
    const statisticsData = [
      {
        title: 'Sales',
        total: '245k',
      },
      {
        title: 'Customers',
        total: '12.5k',
      },
      {
        title: 'Product',
        total: '1.54k',
      },
      {
        title: 'Revenue',
        total: '$88k',
      },
    ]
    const resolveStatisticsIconVariation = data => {
      if (data === 'TL') return { icon: mdiCurrencyTry, color: 'primary' }
      if (data === 'EUR') return { icon: mdiCurrencyEur, color: 'success' }
      if (data === 'CHF') return { icon: mdiCash, color: 'warning' }
      if (data === 'GBP') return { icon: mdiCash, color: 'warning' }
      if (data === 'USD') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      // userBalanceTable,
      // fetchList,

      // icons
      icons: {
        mdiDotsVertical,
        mdiCurrencyTry,
        mdiAccountOutline,
        mdiCash,
        mdiCurrencyUsd,
        mdiCurrencyEur,
      },
    }
  },
  mounted() {
    this.loadCurrenciesData()
    this.loadUserBalanceData()
  },
  methods: {
    async loadUserBalanceData() {
      let company
      if (store.state.companyCustomer) {
        company = store.state.companyCustomer
      }

      const loadingSelectStatus = ref(null)
      const loadingSelectText = ref('Lütfen Bekleyin Yükleniyor')
      const params = new URLSearchParams()
      params.append('method', 'getUserBalances')
      params.append('web_api', 1)
      params.append('company', company)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }

          this.userBalanceData = response.data.response
          this.calculateConvertedBalances() // Veriyi aldıktan sonra hesaplamayı çağırın
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    },

    async loadCurrenciesData() {
      const loadingSelectStatus = ref(null)
      const loadingSelectText = ref('Lütfen Bekleyin Yükleniyor')
      const params = new URLSearchParams()
      params.append('method', 'getCurrencies')
      params.append('web_api', 1)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }

          // this.CurrenciesData = response.data.response

          const exchangeRates = {}
          response.data.response.forEach(item => {
            exchangeRates[item.doviz] = parseFloat(item.kur)
          })
          this.exchangeRates = exchangeRates
          this.calculateConvertedBalances() // Veriyi aldıktan sonra hesaplamayı çağırın
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    },

    calculateConvertedBalances() {
      this.totalBalance = 0
      this.convertedBalanceData = this.userBalanceData.map(data => {
        if (data.title === 'TL') {
          this.totalBalance += parseFloat(data.bakiye)

          return {
            ...data,
            convertedBalance: data.bakiye,
          }
        }

        const rate = this.exchangeRates[data.title]
        const convertedBalance = rate ? (data.bakiye * rate).toFixed(2) : 'N/A'
        if (rate) {
          this.totalBalance += parseFloat(convertedBalance)
        }

        return {
          ...data,
          convertedBalance,
        }
      })
      this.totalBalance = this.totalBalance.toFixed(2)
    },
  },
}
</script>
<style lang="scss" scoped>
.greeting-card {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}

.responsive-text {
  font-size: 14px; /* Varsayılan font boyutu */
}

@media (max-width: 600px) {
  .responsive-text {
    font-size: 8px; /* Küçük ekranlar için font boyutu */
  }
}

@media (max-width: 960px) {
  .responsive-text {
    font-size: 10px; /* Orta boyutlu ekranlar için font boyutu */
  }
}

.custom-container {
  max-width: 2100px; /* İstediğiniz maksimum genişlik */
}

.app-content-container.mx-auto.h-full.app-content-container-boxed {
  padding: 0.5rem !important;
}
</style>
