<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" v-if="!$vuetify.breakpoint.xsOnly">
        <StatisticsCardPieChart
          class="pie-chart"
          :chartSeries="dataPieSeries1"
          :statistics="dataTitle1"
          :chartLabels="dataPieLabels1"
        >
        </StatisticsCardPieChart>
      </v-col>
      <v-col cols="12" md="4" v-if="$vuetify.breakpoint.xsOnly">
        <StatisticsCardPieChart
          class="pie-chart"
          :chartSeries="dataPieSeries1"
          :statistics="dataTitle1"
          :chartLabels="dataPieLabels1"
        >
        </StatisticsCardPieChart>
      </v-col>
    </v-row>

    <v-switch v-model="showZeroBalance" label="Bakiyesi 0 olan hesapları da göster" class="custom-switch" />
    <v-expansion-panels v-model="expanded" multiple>
      <v-expansion-panel v-for="(group, index) in filteredGroupedItems" :key="index">
        <v-expansion-panel-header @click="togglePanel(group.groupName)">
          <VAvatar
            size="60px"
            style="background-color: transparent; border-radius: 10px"
            :color="group.items[0] && group.items[0].bank_logo ? '' : 'primary'"
            :class="
              group.items[0] && group.items[0].bank_logo ? 'v-avatar-light-bg2' : 'v-avatar-light-bg primary--text'
            "
            :variant="!group.items[0] || !group.items[0].bank_logo ? 'tonal' : undefined"
          >
            <VImg v-if="group.items[0] && group.items[0].bank_logo" :src="group.items[0].bank_logo" class="small-img" />
          </VAvatar>

          <div class="header-content" v-if="$vuetify.breakpoint.xsOnly">
            <span class="bank-info"> {{ group.groupName }} ({{ group.items.length }} Hesap) </span>

            <VChip class="bank-info v-chip-light-bg primary--text font-weight-semibold">
              {{ getGroupTotalBalance(group.items) | currency({ symbol: 'TL' }) }}
            </VChip>
          </div>
          <div class="d-flex justify-start align-center" style="width: 100%">
            <span v-if="!$vuetify.breakpoint.xsOnly" class="bank-info">
              {{ group.groupName }} ({{ group.items.length }} Hesap)
            </span>

            <VChip
              v-if="!$vuetify.breakpoint.xsOnly"
              class="bank-info v-chip-light-bg primary--text font-weight-semibold d-flex justify-end"
              style="margin-left: auto; flex: 1; max-width: 180px"
            >
              {{ getGroupTotalBalance(group.items) | currency({ symbol: 'TL' }) }}
            </VChip>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="panel-content">
          <v-data-table :headers="tableColumns" :items="group.items" item-key="id" :items-per-page="5">
            <template v-slot:item.dovizKodu="{ item }">
              <VChip
                :color="item.bakiye < 0 ? 'red' : resolveStatusVariant(item.dovizKodu).color"
                class="font-weight-medium custom-chip"
                :style="{
                  width: '50px',
                  backgroundColor: item.bakiye < 0 ? 'red' : resolveStatusVariant(item.dovizKodu).color,
                  color: 'white', // Yazı rengi için
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }"
              >
                {{ item.dovizKodu }}
              </VChip>
            </template>

            <template v-slot:item.bakiye="{ item }">
              <div class="d-flex justify-start" style="width: 100%">
                <VChip
                  :color="item.bakiye < 0 ? 'red' : resolveStatusVariant(item.dovizKodu).color"
                  label
                  outlined
                  small
                  class="custom-chip d-flex justify-end"
                  :style="{
                    width: '120px',
                    border: '2px solid',
                    borderColor: item.bakiye < 0 ? 'red' : resolveStatusVariant(item.dovizKodu).color,
                  }"
                  @click.prevent="navigateToTransaction(item.id)"
                >
                  {{ item.bakiye | currency({ symbol: item.dovizKodu }) }}
                </VChip>
              </div>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import StatisticsCardPieChart from '@core/components/statistics-card/StatisticsCardPieChart.vue'
import {
  mdiAccountCashOutline,
  mdiAccountOutline,
  mdiAccountReactivate,
  mdiArrowRightBold,
  mdiBankTransfer,
  mdiChevronDown,
  mdiChevronRight,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEye,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiMagnify,
  mdiMinus,
  mdiPlus,
  mdiPoll,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
export default {
  components: {
    StatisticsCardPieChart,
  },
  data() {
    return {
      groupHeaderStyle: {
        backgroundColor: '#3B35BA', // Arka plan rengini burada ayarlayın
      },
      expanded: [], // Grupların genişletilme durumu
      singleExpand: false, // Tek bir grubun açılmasına izin verir
      showZeroBalance: false, // Kullanıcının 0 bakiye olanları gösterip göstermediğini kontrol eder
    }
  },
  setup() {
    const dataPieSeries1 = ref([]) // Başlangıçta boş
    const dataPieLabels1 = ref([]) // Başlangıçta boş
    const dataTitle1 = ref('Grafik Başlığı')

    const expanded = ref([]) // Grupların başlangıçta kapalı olmasını sağlar
    const singleExpand = ref(false) // Tek bir grubun açılmasına izin verir
    const showZeroBalance = ref(false)
    const tableColumns = computed(() => [
      {
        text: 'Bakiye',
        value: 'bakiye',
        width: '100px',
      },
      {
        text: 'Hesap No',
        value: 'hesapNo',
        width: '80px',
      },
      {
        text: 'Döviz',
        value: 'dovizKodu',
        width: '50px',
      },
    ])

    const resolveStatusVariant = status => {
      if (status === 'TL') return { color: 'primary' }
      if (status === 'EUR') return { color: 'success' }
      if (status === 'USD') return { color: 'tertiary' }
      if (status === 'Resigned') return { color: 'warning' }

      return { color: 'info' }
    }

    onMounted(() => {
      fetchList().then(() => loadCurrenciesData())
    })

    const dataListTable = ref([])
    const dovizKodu = ref('')
    const convertedBalanceData = ref([])
    const totalBalance = ref(0)
    const exchangeRates = ref({})

    const fetchList = async () => {
      // this.loading = true
      let company
      if (store.state.companyCustomer) {
        company = store.state.companyCustomer
      }
      const params = new URLSearchParams()
      params.append('method', 'getUserBankAccounts')
      params.append('web_api', 1)
      params.append('company', company)
      axios
        .post('', params)
        .then(response => {
          if (response.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
          dataListTable.value = response.data.response

          expanded.value = [] // Tüm gruplar başlangıçta kapalı

          updateGroupedItems()
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {})
    }
    // Sonuçları işlemek için separate bir function
    const updateGroupedItems = () => {
      const items = groupedItems.value
      const labels = []
      const series = []

      items.forEach(group => {
        const total = parseFloat(getGroupTotalBalance(group.items))
        if (isNaN(total) || total === Infinity) {
          console.warn('Geçersiz toplam:', total)
          return // Geçersiz verileri atla
        }

        group.items.forEach(item => {
          if (!labels.includes(item.bank_title)) {
            labels.push(item.bank_title)
            series.push(total)
          } else {
            const index = labels.indexOf(item.bank_title)
            series[index] += total
          }
        })
      })

      // NaN değerlerini kontrol et
      if (series.some(value => isNaN(value))) {
        console.warn('Seri değerlerinde NaN bulundu:', series)
      }

      // const safeSeries = formattedSeries.map(value => (isNaN(value) ? 0 : value)) // NaN yerine 0 koy

      // Reaktif verileri güncelle
      dataPieLabels1.value = labels
      dataPieSeries1.value = series
      dataTitle1.value = 'Banka - Bakiye Yüzdesi'

      console.log(' dataPieSeries1.value', dataPieSeries1.value)
    }

    const loadCurrenciesData = async () => {
      const loadingSelectStatus = ref(null)
      const loadingSelectText = ref('Lütfen Bekleyin Yükleniyor')
      const params = new URLSearchParams()
      params.append('method', 'getCurrencies')
      params.append('web_api', 1)
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }
          if (response.data.error_code === 99) {
            Vue.swal({
              title: 'Oturum Sonlandı!',
              html: 'Geçersiz Token',
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            router.push('/login')

            return
          }

          response.data.response.forEach(item => {
            exchangeRates.value[item.doviz] = parseFloat(item.kur)
          })
          console.log('exchangeRates', exchangeRates.value)
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const getGroupTotalBalance = items => {
      return items
        .reduce((total, item) => {
          if (item.dovizKodu === 'TL') {
            return total + parseFloat(item.bakiye)
          }
          const rate = exchangeRates.value[item.dovizKodu]
          return total + (rate ? parseFloat(item.bakiye) * rate : 0)
        }, 0)
        .toFixed(2)

      // Grafik verileri için etiketler ve seriler oluştur
      /*    const labels = []
      const series = []

      const totalSum = items
        .reduce((total, item) => {
          if (item.dovizKodu === 'TL') {
            const cleanedBakiyeTL =
              item.bakiye != null ? (typeof item.bakiye === 'string' ? item.bakiye.trim() : item.bakiye) : ''
            const amount =
              !isNaN(parseFloat(cleanedBakiyeTL)) && typeof cleanedBakiyeTL === 'string'
                ? parseFloat(cleanedBakiyeTL)
                : !isNaN(cleanedBakiyeTL) && typeof cleanedBakiyeTL === 'number'
                ? cleanedBakiyeTL
                : 0 // Varsayılan değer
            console.log('amount TL:', amount)
            const convertedAmount = total + amount

            // Etiket ve seriyi doldur
            if (!labels.includes(item.bank_title)) {
              labels.push(item.bank_title)
              series.push(convertedAmount)
            } else {
              // Eğer etiket zaten varsa, seriyi güncelle
              const index = labels.indexOf(item.bank_title)
              series[index] += convertedAmount
            }

            return convertedAmount
          }

          const rate = exchangeRates.value[item.dovizKodu]
          //const amount = typeof item.bakiye === 'string' ? parseFloat(item.bakiye) : Number(item.bakiye)
          const cleanedBakiye = typeof item.bakiye === 'string' ? item.bakiye.trim() : item.bakiye
          const amount =
            typeof cleanedBakiye === 'string' && !isNaN(parseFloat(cleanedBakiye))
              ? parseFloat(cleanedBakiye)
              : typeof cleanedBakiye === 'number' && !isNaN(cleanedBakiye)
              ? cleanedBakiye
              : 0 // Varsayılan değer

          const convertedAmount = total + (rate ? amount * rate : 0)

          // Etiket ve seriyi doldur
          if (!labels.includes(item.bank_title)) {
            labels.push(item.bank_title)
            series.push(convertedAmount)
          } else {
            // Eğer etiket zaten varsa, seriyi güncelle
            const index = labels.indexOf(item.bank_title)
            series[index] += convertedAmount
          }

          return convertedAmount
        }, 0)
        .toFixed(2)

      return totalSum */
    }
    const getGroupTotalBalance1 = items => {
      const total = items.reduce((total, item) => {
        const bakiye = parseFloat(item.bakiye)
        if (isNaN(bakiye)) {
          console.warn('Geçersiz bakiye:', item.bakiye)
          return total // Geçersiz bakiyeyi atla
        }

        if (item.dovizKodu === 'TL') {
          return total + bakiye
        }

        const rate = exchangeRates.value[item.dovizKodu]
        if (rate === undefined) {
          console.warn('Geçersiz döviz kuru:', item.dovizKodu)
          return total // Geçersiz döviz kuru durumunda atla
        }

        return total + (rate ? bakiye * rate : 0)
      }, 0)

      return parseFloat(total).toFixed(2)
    }

    const togglePanel = groupName => {
      const index = expanded.value.indexOf(groupName)
      if (index === -1) {
        expanded.value.push(groupName)
      } else {
        expanded.value.splice(index, 1)
      }
    }

    const groupedItems = computed(() => {
      const groups = dataListTable.value.reduce((acc, item) => {
        const groupName = item.bank_title
        if (!acc[groupName]) {
          acc[groupName] = { groupName, items: [] }
        }
        acc[groupName].items.push(item)
        return acc
      }, {})
      console.log('Grouped Items:', Object.values(groups)) // Debugging
      return Object.values(groups)
    })

    const initializeGroupExpandState = () => {
      // expanded dizisini veri yüklendikten sonra ayarlamak için kullanın
      dataListTable.value.forEach(item => {
        if (!expanded.includes(item.bank_title)) {
          expanded.push(item.bank_title)
        }
      })
    }

    /*    const filteredGroupedItems = computed(() => {
      return groupedItems.value.map(group => ({
        ...group,
        items: group.items.filter(item => showZeroBalance.value || item.bakiye > 0),
      }))
    }) */

    return {
      fetchList,
      loadCurrenciesData,
      dataListTable,
      tableColumns,
      dovizKodu,
      resolveStatusVariant,
      totalBalance,
      convertedBalanceData,
      exchangeRates,
      getGroupTotalBalance,
      expanded,
      singleExpand,
      groupedItems,
      showZeroBalance,
      togglePanel,
      dataPieSeries1,
      dataPieLabels1,
      dataTitle1,
      updateGroupedItems,
      icons: {
        mdiPoll,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
        mdiBankTransfer,
        mdiAccountCashOutline,
        mdiAccountReactivate,
        mdiChevronRight,
        mdiChevronDown,
        mdiArrowRightBold,
        mdiFilter,
        mdiMinus,
        mdiPlus,
        mdiEye,
      },
    }
  },

  methods: {
    getIcon(props) {
      // props.icon veya başka bir özellik üzerinden ikonunuzu belirleyin
      // return 'mdiDeleteOutline'
      return props.isOpen ? 'mdiChevronUp' : 'mdiChevronDown'
    },

    navigateToTransaction(itemId) {
      // Örnek: Mevcut state'i Vuex'e kaydet
      console.log('ATL company', this.$store.state.companyCustomer)
      this.$store.commit('COMPANY_CUSTOMER', this.$store.state.companyCustomer)

      this.$router.push({ path: `/accounts/transactions/list/${itemId}` })
    },

    /*    togglePanel(groupName) {
      const index = this.expanded.indexOf(groupName)
      if (index === -1) {
        this.expanded.push(groupName)
      } else {
        this.expanded.splice(index, 1)
      }
    }, */
  },

  computed: {
    filteredGroupedItems() {
      const groups = this.dataListTable.reduce((acc, item) => {
        if (!this.showZeroBalance && parseFloat(item.bakiye) === 0) {
          return acc
        }

        const groupName = item.bank_title
        if (!acc[groupName]) {
          acc[groupName] = { groupName, items: [] }
        }
        acc[groupName].items.push(item)
        return acc
      }, {})

      return Object.values(groups)
    },
  },
}
</script>
<style scoped>
.small-img {
  width: 100%; /* İstediğiniz genişliği ayarlayın */
  height: 30px; /* İstediğiniz yüksekliği ayarlayın */
  object-fit: cover; /* Resmi kapsayıcıya uydurur */
}
.v-row-group__header {
  background-color: #f0f0f0 !important; /* Standart arka plan rengi */
}

.v-row-group__header:hover {
  background-color: #d0d0d0 !important; /* Hover durumunda arka plan rengi */
}
.bank-info {
  margin-left: 60px; /* Avatar sonrası metin için boşluk eklemek için */
}
.custom-chip {
  font-size: 10px; /* İhtiyaca göre ayarla */
  height: 20px; /* İhtiyaca göre ayarla */
  line-height: 20px; /* İhtiyaca göre ayarla */
}
.right-align {
  text-align: right;
}
.pie-chart {
  max-width: 100%;
  overflow: hidden;
}
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.v-data-footer {
  background-color: transparent !important;
}

.app-card-actions {
  overflow-y: auto; /* Yalnızca dikey scroll barı görüntülemek için */
  /* veya */
  //overflow: auto; /* Hem dikey hem de yatay scroll barları görüntülemek için */
  max-height: 250px; /* Opsiyonel: İçeriğin maksimum yüksekliğini belirleyebilirsiniz */
}
.app-card-actions-mobil {
  overflow-y: auto; /* Yalnızca dikey scroll barı görüntülemek için */
  /* veya */
  //overflow: auto; /* Hem dikey hem de yatay scroll barları görüntülemek için */
  max-height: 200px; /* Opsiyonel: İçeriğin maksimum yüksekliğini belirleyebilirsiniz */
}

.header-content {
  display: flex;
  align-items: center;
  gap: 10px; /* Aradaki boşluğu ayarlayın */
}

.bank-info {
  margin-left: 10px; /* Avatar ile metin arasındaki boşluk */
}

/* Mobil görünüm için düzenleme */
@media (max-width: 600px) {
  .header-content {
    flex-direction: column; /* Alt alta düzenleme */
    align-items: flex-start; /* Sol tarafa hizala */
  }

  .bank-info {
    margin-left: 0; /* Mobilde boşluğu kaldır */
    margin-bottom: 5px; /* Metin ile chip arasına boşluk ekle */
  }

  .v-chip {
    font-size: 12px; /* Font boyutunu küçült */
    padding: 4px 8px; /* İç boşluğu ayarla */
  }
  .data-table {
    max-height: 300px; /* Mobilde tablonun yüksekliğini ayarlayın */
    overflow-y: auto; /* Yalnızca dikey kaydırma */
  }
}

.custom-switch {
  display: flex;
  align-items: center;
  gap: 8px; /* Switch ve etiket arasında boşluk */
}

.custom-switch .v-switch .v-input--selection-controls__input {
  border-radius: 20px; /* Yuvarlak switch */
}

.custom-switch .v-switch .v-switch__track {
  background-color: #e0e0e0; /* Switch arka plan rengi */
}

.custom-switch .v-switch .v-switch__thumb {
  background-color: #3b35ba; /* Switch buton rengi */
}

.custom-switch .v-switch .v-switch--input.is-checked .v-switch__track {
  background-color: #3b35ba; /* Seçili durumda switch arka plan rengi */
}

.custom-switch .v-switch .v-switch--input.is-checked .v-switch__thumb {
  background-color: #fff; /* Seçili durumda switch buton rengi */
}
</style>
