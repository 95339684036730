var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"account-transactions-list"}},[_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar-title',{staticClass:"text-body-2 text-md-h6 font-weight-medium d-flex justify-space-between align-center"},[_c('v-card-title',{staticClass:"align-start"},[_c('v-icon',{attrs:{"large":!_vm.$vuetify.breakpoint.smAndDown,"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBankTransfer)+" ")]),_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" Son Hesap Hareketleri ")]),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.listHareketler}},[_vm._v(" TÜMÜNÜ GÖR ")]):_vm._e()],1),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"view-all-button",attrs:{"color":"error","text":""},on:{"click":_vm.listHareketler}},[_vm._v(" TÜMÜNÜ GÖR ")]):_vm._e()],1),_c('v-spacer'),_c('v-sheet',{style:(_vm.$vuetify.breakpoint.xsOnly ? 'padding-top:20px' : null)},[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"item-key":'unique_id',"headers":_vm.tableColumns,"items":_vm.dataListTable,"sort-desc":true,"loading":_vm.loading,"fixed-header":"","items-per-page":10,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.islemTarihi",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(item.islemTarihi,'Do MMMM YYYY'))+" ")])]}},{key:"item.BankaKodu",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-medium text-high-emphasis text-truncate"},[_vm._v(_vm._s(item.BankaKodu))]),_c('small',[_vm._v(_vm._s(item.IBAN))])])]}},{key:"item.tutar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c('VChip',{staticClass:"custom-chip d-flex justify-end",style:({
                      width: '98px',
                      border: '2px solid',
                      borderColor: item.tutar < 0 ? 'red' : 'green',
                    }),attrs:{"color":item.tutar < 0 ? 'red' : 'green',"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.tutar,{ symbol: item.dovizKodu }))+" ")])],1)]}},{key:"item.aciklama",fn:function(ref){
                    var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(item.aciklama)+" ")])]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }